import React from "react"
import { useState, useEffect, useRef } from "react"
import io from 'socket.io-client';
import eventBus from "./EventBus";
import axios from "axios";
import "../styles/indicator.scss"
import {navigate} from "gatsby"

const WSConnectionIndicator2 = ({dataUrlChangedCallback}) => {
    const socketRef = useRef(null)
    const roomCodeRef = useRef(null)
    const [isBrowserConnected, setIsBrowserConnected] = useState(false)
    const [isPhoneConnected, setIsPhoneConnected] = useState(false)
    const [dataUrl, setDataUrl] = useState(null)
    
    //https://socket.io/how-to/use-with-react-hooks
    useEffect(() => {
      if(socketRef.current == null){
        if(process.env.GATSBY_SOCKET_URL == ""){
          socketRef.current = io()
        }
        else{
          socketRef.current = io(process.env.GATSBY_SOCKET_URL)
        }
      }

      const {current: socket} = socketRef

      //connect and disconnect (on this website component) are handled here.
      socket.on('connect', () => {
        setIsBrowserConnected(true)
        if(roomCodeRef.current == null){
            socket.emit('newRoom')
        }
        else{
            socket.emit('gameJoinRoom', roomCodeRef.current)
        }
      });

      socket.on('disconnect', () => {
        setIsBrowserConnected(false)
        setIsPhoneConnected(false)
        setDataUrl(null)
        dataUrlChangedCallback(null)
        eventBus.dispatch(eventBus.eventNames.connectionEvent, {connectionReady: false});
      });

      //Things handled by UI on the Connection Indicator
      socket.on('roomCode', (data) => {
        roomCodeRef.current = data.roomCode
        setDataUrl(data.dataUrl)
        dataUrlChangedCallback(data.dataUrl)
      })

      //This one is handled by the UI Indicator, but also sent to the game.
      socket.on('connectionEvent', (data) => {
        setIsPhoneConnected(data.isConnectionReady)
        eventBus.dispatch(eventBus.eventNames.connectionEvent, data);

        const isBrowser = typeof window !== "undefined"
        if(isBrowser && data.justScannedBarcode){
          if(window.location.href.indexOf("/play") < 1 && window.location.href.indexOf("/games") < 1)
            navigate("/games/")
        }
      })

      //Things that go directly to the game/iframe are handled like this.
      socket.on(eventBus.eventNames.poseEvent, (result) => {
        eventBus.dispatch(eventBus.eventNames.poseEvent, result)
      })

      socket.on(eventBus.eventNames.savedData, (result) => {
        eventBus.dispatch(eventBus.eventNames.savedData, result)
      })
  
      return () => {
        socket.off('connect')
        socket.off('disconnect')
        socket.off('roomCode')
        socket.off('connectionEvent')
        socket.off(eventBus.eventNames.bodyActionData)
        socket.off(eventBus.eventNames.systemData)
        socket.off(eventBus.eventNames.savedData)
      };
    }, [dataUrlChangedCallback]);

    //Anything coming from the iFrame/game needs to use this format
    // useEffect(() => {
    //     function handleEventBusMessageFromGame(data){
    //         socketRef.current.emit("GameData", data.detail)
    //     }
    //     eventBus.on(eventBus.eventNames.WSMessageFromGame, handleEventBusMessageFromGame)
    //     return () => {
    //         eventBus.remove(eventBus.eventNames.WSMessageFromGame, handleEventBusMessageFromGame)
    //     }
    // }, [])
    useEffect(() => {
      function handleEventBusMessageFromGame(data){
          socketRef.current.emit(eventBus.eventNames.getConnectionStatus, data.detail)
      }
      eventBus.on(eventBus.eventNames.getConnectionStatus, handleEventBusMessageFromGame)
      return () => {
          eventBus.remove(eventBus.eventNames.getConnectionStatus, handleEventBusMessageFromGame)
      }
  }, [])


  useEffect(() => {
      function handleEventBusMessageFromGame(data){
          socketRef.current.emit(eventBus.eventNames.registerForBodyAction, data.detail)
      }
      eventBus.on(eventBus.eventNames.registerForBodyAction, handleEventBusMessageFromGame)
      return () => {
          eventBus.remove(eventBus.eventNames.registerForBodyAction, handleEventBusMessageFromGame)
      }
  }, [])

  useEffect(() => {
    function handleEventBusMessageFromGame(data){
        socketRef.current.emit(eventBus.eventNames.registerSystemDataCollector, data.detail)
    }
    eventBus.on(eventBus.eventNames.registerSystemDataCollector, handleEventBusMessageFromGame)
    return () => {
        eventBus.remove(eventBus.eventNames.registerSystemDataCollector, handleEventBusMessageFromGame)
    }
}, [])

useEffect(() => {
  function handleEventBusMessageFromGame(data){
      socketRef.current.emit(eventBus.eventNames.clearBodyActionCollectors, data.detail)
  }
  eventBus.on(eventBus.eventNames.clearBodyActionCollectors, handleEventBusMessageFromGame)
  return () => {
      eventBus.remove(eventBus.eventNames.clearBodyActionCollectors, handleEventBusMessageFromGame)
  }
}, [])

useEffect(() => {
  function handleEventBusMessageFromGame(data){
      socketRef.current.emit(eventBus.eventNames.clearSystemCollectors, data.detail)
  }
  eventBus.on(eventBus.eventNames.clearSystemCollectors, handleEventBusMessageFromGame)
  return () => {
      eventBus.remove(eventBus.eventNames.clearSystemCollectors, handleEventBusMessageFromGame)
  }
}, [])

useEffect(() => {
  function handleEventBusMessageFromGame(data){
      socketRef.current.emit(eventBus.eventNames.getSavedData, data.detail)
  }
  eventBus.on(eventBus.eventNames.getSavedData, handleEventBusMessageFromGame)
  return () => {
      eventBus.remove(eventBus.eventNames.getSavedData, handleEventBusMessageFromGame)
  }
}, [])

useEffect(() => {
  function handleEventBusMessageFromGame(data){
      socketRef.current.emit(eventBus.eventNames.setSavedData, data.detail)
  }
  eventBus.on(eventBus.eventNames.setSavedData, handleEventBusMessageFromGame)
  return () => {
      eventBus.remove(eventBus.eventNames.setSavedData, handleEventBusMessageFromGame)
  }
}, [])


    //This is just to make sure the server doesn't go to sleep.
    useInterval(() => {
      if(`${process.env.GATSBY_KEEP_ALIVE_URL}` !== ""){
        axios(`${process.env.GATSBY_KEEP_ALIVE_URL}`)
        .then((response) => {
            console.log(`Kept Alive: ${new Date()}`)
        })
        .catch((e) => {
            console.warn(`Keep Alive FAILED: ${new Date()} ${e}`)
        })
      }
    }, isBrowserConnected ? 150000 : null)

    function shouldShow(){
      const isBrowser = typeof window !== "undefined"
      if(isBrowser){
        return window.location.href.indexOf("/play") > 1
      }
      return false
    }
  
      if(shouldShow()){
          return (
            <div className="corner-floater m-1 p-1 container">
                {dataUrl != null && !isPhoneConnected &&
                    <img src={`${dataUrl}`} alt={`QRCode for linking to phone`} />
                }
                <div className={`${isBrowserConnected ? "green-circle" : "red-circle"} indicator`}></div>
                <div className={`${isPhoneConnected ? "green-circle" : "red-circle"} indicator`}></div>
            </div>
          )
        }
        else{
          return <></>
        }
}

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

export default WSConnectionIndicator2