const eventBus = {
    on(event, callback) {
      document.addEventListener(event, callback);
    },
    dispatch(event, data) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
      document.removeEventListener(event, callback);
    },
    eventNames: {
      //OLD:
      // WSMessageFromGame: 'WSMessageFromGame',
      // WSMessageFromPhone: 'WSMessageFromPhone',

      

      //ToGame:
      connectionEvent: 'connectionEvent', //technically this goes to the phone too, but it gets sent by the server, so it doesn't go through the client side.
      poseEvent: 'poseEvent',
      savedData: 'savedData',
      //ToServer
      getConnectionStatus: 'getConnectionStatus',
      //ToPhone
      registerForBodyAction: 'registerForBodyAction',
      registerSystemDataCollector: 'registerSystemDataCollector',
      clearBodyActionCollectors: 'clearBodyActionCollectors',
      clearSystemCollectors: 'clearSystemCollectors',
      getSavedData: 'getSavedData',
      setSavedData: 'setSavedData',
    }
  };
  
  export default eventBus;