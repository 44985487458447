import * as React from "react"
import PropTypes from "prop-types"
import { useState} from "react"
import { DataUrlContext } from "../hooks/dataUrlContext.js"

// import "../styles/layout.scss"
import WSConnectionIndicator2 from "./indicator2"

const PageWrap = ({ children }) => {

    const [dataUrl, setDataUrl] = useState(null)
    

    // function dataUrlChangedCallback(dataUrl){
    //     alert(dataUrl);
    // }

  return (
    <>
        <section className="d-flex flex-column h-100">
          <DataUrlContext.Provider value={dataUrl}>
              {children}
              <WSConnectionIndicator2 dataUrlChangedCallback={setDataUrl}></WSConnectionIndicator2>
          </DataUrlContext.Provider>
        </section>
    </>
    // <div className="container-fluid p-0">
    //   <Navbar siteTitle={data.site.siteMetadata?.title || `Title`} />
    //   <main>{children}</main>
    //   {/* <WSConnectionIndicator2></WSConnectionIndicator2> */}
    // </div>
  )
}

PageWrap.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageWrap